import './Services.css';

function LogoDesign(props) {
    return (
        <div className="services">
            <div className="ds pos">
                <div className="container">
                    <h1>Logo Design</h1>
                </div>
            </div>

            <div className="container mt-5 content">
                <p>Elevate your brand's identity with our expert Logo Design services. Our skilled designers combine creativity and strategic thinking to craft logos that capture the essence of your business.</p>
                <p>Whether you're starting a new business or rebranding your current one, we'll work closely with you to comprehend your goals and translate them into an impactful logo. Our creative work strikes a chord with your target market, leaving a lasting impression and laying a foundation for a powerful brand presence. With a logo that expresses your narrative and leaves a lasting impact, stand out in the community.</p>
            </div>
        </div>
    )
};

export default LogoDesign;