import React, { Component } from 'react'

import './works.css'

const branding = require.context('../../assets/static-branding', true)
const brandingList = branding.keys().map(image => branding(image))

const development = require.context('../../assets/static-development', true)
const developmentList = development.keys().map(image => development(image))

const brands = [
  { handle: 'TeletalkBangladeshLtd', name: 'Teletalk Bangladesh Ltd' }, 
  { handle: 'Alljobs', name: 'Alljobs by Teletalk' },
  { handle: 'Doel', name: 'Doel' },
  { handle: 'BaseTechnologies', name: 'Base Technologies Ltd' },
  { handle: 'LegalX', name: 'LegalX' },
  { handle: 'AnchorLegal', name: 'Anchor Legal' },
  { handle: 'Czar', name: 'C\'zar' },
  { handle: 'Casata', name: 'Casata' },
  { handle: 'AveneurSolutions', name: 'Aveneur Solutions' },
  { handle: 'YourLawyer', name: 'Your Lawyer' },
  { handle: 'ModernMuchi', name: 'Modern Muchi' },
  { handle: 'Eduark', name: 'Eduark' },
  { handle: 'RampuraPhysiotherapy', name: 'Rampura Physiotherapy Centre' },
  { handle: 'Telecure', name: 'Telecure' },
  { handle: 'Ujobs', name: 'Ujobs' },
  { handle: 'TezzFeed', name: 'Tezzfeed' },
  { handle: 'Telemart', name: 'Telemart' },
  { handle: 'Others', name: 'Others' }
]

const develops = [
  { handle: 'AkhtarImam_Associates', name: 'Akhtar Imam & Associates' },
  { handle: 'ShawnNovel_Associates', name: 'Shawn Novel & Associates' },
  { handle: 'LegalX', name: 'LegalX' },
  { handle: 'AnchorLegal', name: 'Anchor Legal' },
  { handle: 'GetDrafted', name: 'Get Drafted' }, 
  { handle: 'Codified', name: 'Codified' }, 
  { handle: 'YourLawyer', name: 'Your Lawyer' }
]

export class Works extends Component {
  constructor() {
    super()

    this.state = {
      selectedCat: '',
      brandImg: [],
      developmentImg: []
    }

    this.filter = this.filter.bind(this)
  }

  componentDidMount() {
    this.setState({ jobs: this.state.allJobs, selectedCat: 'Branding' })

    let brandWork = []
    let developmentWork = []

    brandingList.map((image) => {
      for (let i = 0; i < brands.length; i++) {  
        const imgSrc = image.split('/')[3]
        const imgName = imgSrc.split(' ')[0];
        const imgType = imgSrc.split(' ')[1]

        if (brands[i].handle === imgName) {
          brandWork.push({ name: imgName, src: image, type: imgType  })
        }
      }
      return this.setState({ brandImg: brandWork })
    })

    developmentList.map((image) => {
      for (let i = 0; i < develops.length; i++) {  
        const imgSrc = image.split('/')[3]
        const imgName = imgSrc.split(' ')[0]

        if (develops[i].handle === imgName) {
          developmentWork.push({ name: imgName, src: image })
        }
      }
      return this.setState({ developmentImg: developmentWork })
    })
 
  }

  filter(cat) {
    this.setState({ selectedCat: cat })
  }

  render() {
    return (
      <div className='works'>

        <div className="heading text-center">
            <h1>OUR <span className='bold'>WORKS</span></h1>
            <h4 className='tagline text-black'>A small preview of our diverse portfolio</h4>
        </div>

        <div className="work mt-2">
          <ul className="works-nav">
            <li className={(this.state.selectedCat === 'Branding') ? 'active' : ''} onClick={() => this.filter('Branding')}>Branding</li>
            <li className={(this.state.selectedCat === 'Development') ? 'active' : ''} onClick={() => this.filter('Development')}>Development</li>
          </ul>
        </div>

        {(this.state.selectedCat === 'Branding') ? (
          <div className="branding">
            <div className="container">
              {brands.map((brand, index) => (
                <div className="brand py-4" key={index}>
                  <h1 className="name">{brand.name}</h1>
                  <div className="row gy-4">
                    {this.state.brandImg.map((img, i) => (
                      (img.name === brand.handle) ? (
                        (img.type === 'Picture') ? (
                          <div className="col-md-3" key={i}>
                            <img src={img.src} alt={img.name} className='img-fluid'></img>
                          </div>
                        ) : (img.type === 'Cover') ? (
                          <div className="col-md-6" key={i}>
                            <img src={img.src} alt={img.name} className='img-fluid'></img>
                          </div>
                        ) : null
                      ) : null
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null} 

        {(this.state.selectedCat === 'Development') ? (
          <div className="development">
            <div className="container">
              {develops.map((develop, index) => (
                <div className="develop brand py-4" key={index}>
                  <h1 className="name">{develop.name}</h1>
                  <div className="row gy-4">
                    {this.state.developmentImg.map((img, i) => (
                      (img.name === develop.handle) ? (
                        <div className="col-md-6" key={i}>
                          <img src={img.src} alt={img.name} className='img-fluid' />
                        </div>
                      ) : null
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}

      </div>
    )
  }
}

export default Works