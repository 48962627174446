import './Services.css';

function PrintMedia(props) {
    return (
        <div className="services">
            <div className="ds pos">
                <div className="container">
                    <h1>Print Media Service</h1>
                </div>
            </div>

            <div className="container mt-5 content">
                <p>Maximize the compelling print media's full potential with our comprehensive services. We excel at turning your ideas into practical, premium printed goods, from eye-catching brochures to expertly designed business cards.</p>
                <p>Our team creates visually appealing and informative advertising with a great grasp of your business to make a lasting impression. No matter if it's for events, promotions, or brand visibility, our print media services make sure your message is elegantly and efficiently delivered.</p>
                <p>Ensure that your brand’s message is eye-catching through us with</p>
                <ul>
                  <li>Brochures</li>
                  <li>Brand Cards</li>
                  <li>Flyers</li>
                  <li>Banners & Hoardings</li>
                  <li>Newspaper/Magazine Ads</li>
                  <li>Merchandise Design</li>
                </ul>
                <p>Allow your brand's voice to be heard offline by using modern print media resources for all non-digital strategies.</p>
            </div>
        </div>
    )
};

export default PrintMedia;