import './Services.css';

function SEM(props) {
    return (
        <div className="services">
            <div className="hosting pos">
                <div className="container">
                    <h1>Search Engine Marketing</h1>
                </div>
            </div>

            <div className="container mt-5 content">
                <p>Surpass your competition and connect with the individuals actively seeking your products and services! Forge a strategic advantage by crafting meticulously optimized advertisements that propel your online visibility. Our expertise lies in creating compelling, tailored ads that not only attract but also resonate with your target demographic.</p>
                <p>By harnessing our services, you're empowered to establish a strong digital presence, ensuring your message reaches the right people at the right time, translating into heightened brand recognition and business growth through:</p>
                <ul>
                    <li>Google Ads.</li>
                    <li>Target Marketing.</li>
                    <li>Bidding.</li>
                    <li>Conversion Optimized Landing Page</li>
                    <li>Display Advertising</li>
                    <li>Remarketing Campaigns</li>
                    <li>Lead Generation</li>
                </ul>
            </div>
        </div>
    )
};

export default SEM;