import './Services.css';

function EmailMarketing(props) {
    return (
        <div className="services">
            <div className="discovery pos">
                <div className="container">
                    <h1>Email Marketing</h1>
                </div>
            </div>

            <div className="container mt-5 content">
                <p>Initiate dynamic interactions with your valued customers through regular brand updates, fostering a heightened level of customer engagement that truly sets your business apart. Drawing upon our strategic acumen, we not only envision but meticulously orchestrate impactful email campaigns. These campaigns serve as a conduit for multifaceted benefits, encompassing enhanced brand recognition, the cultivation of valuable leads, and the cultivation of lasting customer relationships. Through our meticulous approach, we ensure that each communication is thoughtfully curated to resonate with your audience, thereby amplifying your brand's resonance and solidifying your position in the market. It includes:</p>
                <ul>
                    <li>Email Content Creation.</li>
                    <li>Email Newsletter.</li>
                    <li>Email Database Building.</li>
                    <li>Email Spam Score Optimization.</li>
                    <li>Customized Email Templates.</li>
                    <li>Email Marketing Analytics.</li>
                </ul>
            </div>
        </div>
    )
};

export default EmailMarketing;