import one from '../../assets/people/1.png'
import two from '../../assets/people/2.png'
import four from '../../assets/people/4.png'
import five from '../../assets/people/5.png'
import six from '../../assets/people/6.png'
import seven from '../../assets/people/7.png'
import eight from '../../assets/people/8.png'
import nine from '../../assets/people/9.png'
import ten from '../../assets/people/10.png'
import twelve from '../../assets/people/12.png'
import eleven from '../../assets/people/11.png'
import './People.css';

function People() {
    return (
        <div className="mtm mt-5">
            <h1>MEET THE <span className='bold'>TEAM</span></h1>
            <h4 className='pb-5 tagline'>Introducing the people behind your creatives</h4>
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <img src={one} alt="Maliha Rahham" className='img-fluid'/>
                        <p className='name'>Maliha Rahham</p>
                        <p className="post">Managing Partner & CEO</p>
                    </div>
                    <div className="col-md-3">
                        <img src={two} alt="Muiz Shahabuddin" className='img-fluid'/>
                        <p className='name'>Muiz Shahabuddin</p>
                        <p className="post">Head of Business</p>
                    </div>
                    <div className="col-md-3">
                        <img src={four} alt="Ashraful Islam" className='img-fluid'/>
                        <p className='name'>Syed Joheb Hassan</p>
                        <p className="post">General Manager Operations</p>
                    </div>
                    {/* <div className="col-md-3">
                        <img src={ten} alt="Promit Karmaker" className='img-fluid'/>
                        <p className='name'>Promit Karmaker</p>
                        <p className="post">General Manager Technology</p>
                    </div> */}
                    <div className="col-md-3">
                        <img src={five} alt="Badiul Islam Fahad" className='img-fluid' />
                        <p className='name'>Md Ashraful Rahman</p>
                        <p className="post">Creative Designer</p>
                    </div>
                    <div className="col-md-3">
                        <img src={six} alt="Sadia Armin Alam" className='img-fluid' />
                        <p className='name'>Badiul Alam Fahad</p>
                        <p className="post">Creative Designer</p>
                    </div>
                    <div className="col-md-3">
                        <img src={seven} alt="Dilip Chandra Das" className='img-fluid' />
                        <p className='name'>Sadia Armin Alam</p>
                        <p className="post">Account Manager</p>
                    </div>
                    <div className="col-md-3">
                        <img src={twelve} alt="Tayeba Akhter" className='img-fluid' />
                        <p className='name'>Tayeba Akhter</p>
                        <p className="post">Customer Service Executive</p>
                    </div>
                    <div className="col-md-3">
                        <img src={eleven} alt="Samia Jahan" className='img-fluid'/>
                        <p className='name'>Samia Jahan</p>
                        <p className="post">Business Development Executive</p>
                    </div>
                    <div className="col-md-3">
                        <img src={nine} alt="Rokaiya Abdullah Raka" className='img-fluid' />
                        <p className='name'>Rokaiya Abdullah Raka</p>
                        <p className="post">Copywriter</p>
                    </div> 
                    <div className="col-md-3">
                        <img src={eight} alt="Dilip Chandra Das" className='img-fluid' />
                        <p className='name'>Dilip Chandra Das</p>
                        <p className="post">Officer Manager</p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default People;