import { faPhone, faEnvelope, faLocationPinLock } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './contact.css'
import Form from '../../components/contact/form'

function Contact() {
  return (
    <div className="contact">
      <div className="container">
        <div className="title">
          <h1 className="heading">Contact us</h1>
          <p>Any question or remarks? Just write us a message</p>
        </div>

        <div className="form">
          <div className="row d-flex align-items-center">
            <div className="col-md-4">
              <div className="contact-title">
                <h3>Contact Information</h3>
                <p>Fill up the form and our Team will get back to you within 24 hours.</p>
              </div>

              <div className="info">
                <div><FontAwesomeIcon className='icon' icon={faPhone}></FontAwesomeIcon> +880 1550-0076875</div>
                <div><FontAwesomeIcon className='icon' icon={faEnvelope}></FontAwesomeIcon> info.senoark@gmail.com</div>
                <div><FontAwesomeIcon className='icon' icon={faLocationPinLock}></FontAwesomeIcon> 326 Palashbagh, West Rampura, Dhaka</div>
              </div>

              <div className="social">
                <FontAwesomeIcon className='icon' size='2x' icon={faFacebook}></FontAwesomeIcon>
                <FontAwesomeIcon className='icon' size='2x' icon={faLinkedin}></FontAwesomeIcon>
                <FontAwesomeIcon className='icon' size='2x' icon={faInstagram}></FontAwesomeIcon>
              </div>
            </div>
            <div className="col-md-8">
              <Form></Form>
              {/* <form action="">
                <div className="row">
                  <div className="col-md-6">
                    <input type="text" className='form-control mb-4' placeholder='First Name' required/>
                  </div>
                  <div className="col-md-6">
                    <input type="text" className='form-control mb-4' placeholder='Last Name' required/>
                  </div>
                  <div className="col-md-6">
                    <input type="email" className='form-control mb-4' placeholder='Email' required/>
                  </div>
                  <div className="col-md-6">
                    <input type="text" className='form-control mb-4' placeholder='Phone Number' required/>
                  </div>
                </div>
                <select className="form-select mb-4" aria-label="Select services">
                    <option defaultValue>Select services</option>
                    <option value="1">Digital Marketing</option>
                    <option value="2">Visual Solutions</option>
                    <option value="3">Website Development</option>
                </select>
                <textarea className="form-control mb-4" placeholder="Your message*" style={{height: '100px'}}></textarea>

                <div className="pb-5">
                  <div className="d-grid">
                    <button className='button text-center' href="https://book.senoarkdigital.com">Send message</button>
                  </div>
                </div>
              </form> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact