import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home, People, Work, DigitalAudit, ContentWriting, EmailMarketing, WordpressWebsite, SEO, PublicRelations, PerformanceMarketing, SocialMediaMarketing, AppDevelopment, SEM, QueryCommunityManagement, WebsiteDevelopment, LogoDesign, PrintMedia, ProductPackage, WebsiteMaintenance, VideoProduce, DigitalDesign, Contact } from './containers/Containers.jsx'; 
import {Footer, Navbar} from './components/Components.jsx';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />}></Route>

          <Route path="/digital-audit" element={<DigitalAudit />}></Route>
          <Route path="/search-engine-optimisation" element={<SEO />}></Route>
          <Route path="/search-engine-marketing" element={<SEM />}></Route>
          <Route path="/performance-marketing" element={<PerformanceMarketing />}></Route>
          <Route path="/social-media-marketing" element={<SocialMediaMarketing />}></Route>
          <Route path="/email-marketing" element={<EmailMarketing />}></Route>
          <Route path="/content-writing" element={<ContentWriting />}></Route>
          <Route path="/public-relations" element={<PublicRelations />}></Route>
          <Route path="/query-community-management" element={<QueryCommunityManagement />}></Route>
          <Route path="/wordpress-development" element={<WordpressWebsite />}></Route>
          <Route path="/website-development" element={<WebsiteDevelopment />}></Route>
          <Route path="/application-development" element={<AppDevelopment />}></Route>
          <Route path="/website-maintenance" element={<WebsiteMaintenance />}></Route>
          <Route path="/logo-design" element={<LogoDesign />}></Route>
          <Route path="/print-media-services" element={<PrintMedia />}></Route>
          <Route path="/product-packaging" element={<ProductPackage />}></Route>
          <Route path="/video-production" element={<VideoProduce />}></Route>
          <Route path="/digital-collateral-design" element={<DigitalDesign />}></Route>
          
          <Route path="/people" element={<People />}></Route>
          <Route path='/our-work' element={<Work />}></Route>
          <Route path='/contact-us' element={<Contact />}></Route>

        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;