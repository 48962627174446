import Logo from '../../assets/logo.png';
import './Navbar.css';

function Navbar() {
    return (
        <nav className="navbar navbar-expand-lg">
            <div className="container">
                <a href="/" className="navbar-brand">
                    <img src={Logo} alt="" width="150px" />
                </a>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">

                    <ul className="navbar-nav ms-auto mb-2 px-3 mb-lg-0">
                        <li className="nav-item d">
                            <a className="nav-link d-btn" id="navbarDropdown" role='button'data-bs-toggle="dropdown" aria-expanded="false" href="#">Services</a>
                            <div className="d-content shadow">
                                <div className="row container mx-auto">
                                    <div className="col-md-4">
                                        <a href="/digital-audit" className='drop-link'>
                                            <h2>Digital Audit</h2>
                                            <p>If you're an individual or organization that fully embraces the potential of digital technology and finds yourself questioning the performance</p>
                                        </a>
                                    </div>
                                    <div className="col-md-4">
                                        <a href="/search-engine-optimisation" className="drop-link">
                                            <h2>Search Engine Optimisation</h2>
                                            <p>Enhance your search engine rank and visibility by leveraging the power of search engine optimization (SEO).</p>
                                        </a>
                                    </div>
                                    <div className="col-md-4">
                                        <a href="/search-engine-marketing" className="drop-link">
                                            <h2>Search Engine Marketing</h2>
                                            <p>Surpass your competition and connect with the individuals actively seeking your products and services!</p>
                                        </a>
                                    </div>
                                    <div className="col-md-4">
                                        <a href="/performance-marketing" className="drop-link">
                                            <h2>Performance Marketing</h2>
                                            <p>Are you in pursuit of business expansion and seeking to unlock revenue streams with a remarkable return on investment?</p>
                                        </a>
                                    </div>
                                    <div className="col-md-4">
                                        <a href="/social-media-marketing" className="drop-link">
                                            <h2>Social Media Marketing</h2>
                                            <p>Capture the attention of your ideal audience by strategically reaching out to them with precisely curated content, precisely when they're most receptive.</p>
                                        </a>
                                    </div>
                                    <div className="col-md-4">
                                        <a href="/email-marketing" className="drop-link">
                                            <h2>Email Marketing</h2>
                                            <p>Initiate dynamic interactions with your valued customers through regular brand updates, fostering a heightened level of customer engagement that truly sets your business apart.</p>
                                        </a>
                                    </div>
                                    <div className="col-md-4">
                                        <a href="/content-writing" className="drop-link">
                                            <h2>Content Writing</h2>
                                            <p>Our expertise lies in the meticulous formulation and crafting of compelling content, carefully tailored for your website, blogs, and a spectrum of resources.</p>
                                        </a>
                                    </div>
                                    <div className="col-md-4">
                                        <a href="/public-relations" className="drop-link">
                                            <h2>Public Relations</h2>
                                            <p>Forge meaningful connections and manage your brand's reputation with our Public Relations expertise.</p>
                                        </a>
                                    </div>
                                    <div className="col-md-4">
                                    <a href="/query-community-management" className="drop-link">
                                        <h2>Query and Community Management </h2>
                                        <p>Navigate the world of online engagement with confidence through our Query and Community Management services.</p>
                                    </a>
                                    </div>
                                    <div className="col-md-4">
                                        <a href="/wordpress-development" className="drop-link">
                                            <h2>WordPress Website Development</h2>
                                            <p>In an ever-evolving digital landscape, WordPress maintains its position as a formidable platform, offering unparalleled versatility that enables the seamless creation of diverse websites.</p>
                                        </a>
                                    </div>
                                    <div className="col-md-4">
                                        <a href="/website-development" className="drop-link">
                                            <h2>Custom Website Development</h2>
                                            <p>Embarking on a successful digital journey requires recognizing that while good design constitutes a significant portion of the equation</p>
                                        </a>
                                    </div>
                                    <div className="col-md-4">
                                        <a href="/application-development" className="drop-link">
                                            <h2>Application Development</h2>
                                            <p>Venturing into the realm of application development demands a keen understanding of the symbiotic relationship between cutting-edge design and the strategic implementation of development processes.</p>
                                        </a>
                                    </div>
                                    <div className="col-md-4">
                                        <a href="/website-maintenance" className="drop-link">
                                            <h2>Website Maintenance</h2>
                                            <p>If the concern of maintaining the situation of your website and consistently evaluating its performance troubles you, rest assured that these are vital aspects in the realm of web management.</p>
                                        </a>
                                    </div>
                                    <div className="col-md-4">
                                        <a href="/logo-design" className="drop-link">
                                            <h2>Logo Design</h2>
                                            <p>Elevate your brand's identity with our expert Logo Design services. Our skilled designers combine creativity and strategic thinking to craft logos that capture the essence of your business.</p>
                                        </a>
                                    </div>
                                    <div className="col-md-4">
                                        <a href="/print-media-services" className="drop-link">
                                            <h2>Print Media Services</h2>
                                            <p>Maximize the compelling print media's full potential with our comprehensive services. We excel at turning your ideas into practical, premium printed goods, from eye-catching brochures to expertly designed business cards.</p>
                                        </a>
                                    </div>
                                    <div className="col-md-4">
                                        <a href="/product-packaging" className="drop-link">
                                            <h2>Product Packaging</h2>
                                            <p>Discover the perfect packaging solution for your products with our exceptional Product Packaging services.</p>
                                        </a>
                                    </div>
                                    <div className="col-md-4">
                                        <a href="/video-production" className="drop-link">
                                            <h2>Video Production</h2>
                                            <p>Whether you want to create marketing videos, engaging educational content, or riveting visual narrative, our team of qualified professionals is here to help you.</p>
                                        </a>
                                    </div>
                                    <div className="col-md-4">
                                        <a href="/digital-collateral-design" className="drop-link">
                                            <h2>Digital Collateral Design</h2>
                                            <p>From appealing social media visuals to eye-catching email templates, we specialize in creating aesthetically engaging designs that engage with your target audience.</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link" href="/our-work">Our Works</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link" href="/people">People</a>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link" href="/#appointment">Get a Quote</a>
                        </li>
                    </ul>
                </div>

            </div>
        </nav>
    )
};

export default Navbar;