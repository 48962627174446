import './Services.css';

function ContentWriting(props) {
    return (
        <div className="services">
            <div className="ongoing pos">
                <div className="container">
                    <h1>Content Writing</h1>
                </div>
            </div>

            <div className="container mt-5 content">
                <p>Our expertise lies in the meticulous formulation and crafting of compelling content, carefully tailored for your website, blogs, and a spectrum of resources. With a keen focus on captivating your audience, our strategic approach seeks to not only capture attention but also to contribute to the narrative that defines your brand. By weaving together narratives that resonate, we assist in the establishment of a distinctive brand story that resonates deeply with your target audience, leaving a lasting impression and fostering meaningful connections by writing contents for</p>
                <ul>
                    <li>Website</li>
                    <li>Ads Description on Social Media Platforms</li>
                    <li>Articles and Blog Posts</li>
                    <li>Email</li>
                    <li>Infographics</li>
                    <li>Testimonials and Customer Stories</li>
                    <li>Guest Blogging and Guest Blogging Outreach</li>
                </ul>
            </div>
        </div>
    )
};

export default ContentWriting;