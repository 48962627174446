import './Services.css';

function PublicRelations(props) {
    return (
        <div className="services">
            <div className="ds pos">
                <div className="container">
                    <h1>Public Relations</h1>
                </div>
            </div>

            <div className="container mt-5 content">
                <p>Forge meaningful connections and manage your brand's reputation with our Public Relations expertise. At our digital marketing agency, we've mastered the art of impactful storytelling through our PR Facebook page, TezzFeed.</p>
                <p>TezzFeed serves as a dynamic platform to engage audiences, spotlight our clients' achievements, and navigate challenging conversations with finesse. Join us in crafting an influential online presence that drives conversations and propels brands to the forefront of their industries.</p>
                <p>Visit <a href='https://facebook.com/TezzFeed' target='_blank' rel="noreferrer">TezzFeed</a></p>
            </div>
        </div>
    )
};

export default PublicRelations;