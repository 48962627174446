import { useCallback } from 'react';
import Particles from 'react-tsparticles';
import './particles.css';
import { loadFull } from 'tsparticles'
import { useEffect } from 'react';

function ParticlesBackground() {

    const particlesInit = useCallback(async (engine) => {
        await loadFull(engine);
    }, []);
    
    const particlesLoaded = useCallback();

    useEffect(() => {
        
    }, [])

    return (
        <div className="particles">
            <Particles id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                background: {
                color: {
                    value: "#ffffff",
                },
                },
                fullScreen: {
                    enable: false
                },
                interactivity: {
                    detect_on: 'window'
                },
                fpsLimit: 120,
                particles: {
                color: {
                    value: "#000000",
                },
                links: {
                    color: "#000000",
                    distance: 150,
                    enable: true,
                    opacity: 0.3,
                    width: 2,
                },
                collisions: {
                    enable: true,
                },
                move: {
                    directions: "none",
                    enable: true,
                    outModes: {
                        default: "bounce",
                    },
                    random: true,
                    speed: 3,
                    straight: false,
                },
                number: {
                    density: {
                        enable: true,
                        area: 1200,
                    },
                    value: 120,
                },
                opacity: {
                    value: 0.3,
                },
                shape: {
                    type: "circle",
                },
                size: {
                    value: { min: 2, max: 4 }
                },
                },
            }}>
            </Particles>

            <div id="text">
                <h1>Your Powerhouse for Digital Marketing Solutions</h1>
            </div>
        </div>
    )
};

export default ParticlesBackground;