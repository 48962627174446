import './Services.css';

function SEO(props) {
    return (
        <div className="services">
            <div className="ds pos">
                <div className="container">
                    <h1>Search Engine Optimisation</h1>
                </div>
            </div>

            <div className="container mt-5 content">
                <p>Enhance your search engine rank and visibility by leveraging the power of search engine optimization (SEO). Our services are designed to not only bolster your online presence but also attract precisely targeted traffic from search engines in an organic manner. We specialize in the art of optimizing your entire website, ensuring that it not only aligns with search engine algorithms but also offers a user-friendly experience.</p>
                <p>With our expert guidance, your digital platform will be poised to achieve heightened search engine rankings and effectively engage your intended audience through: </p>
                <ul>
                    <li>Mobile Optimization</li>
                    <li>Link Building</li>
                    <li>Keyword Optimization</li>
                    <li>Content Optimization</li>
                    <li>Page Speed and Page Size Optimization</li>
                    <li>Image Optimization</li>
                </ul>
            </div>
        </div>
    )
};

export default SEO;