import React, { Component } from 'react'
import emailjs from '@emailjs/browser'
import { SpinnerRoundFilled } from 'spinners-react'

export class Form extends Component {
  constructor() {
    super()

    this.state = {
      formLoading: false,
      name: '',
      phoneNumber: '',
      email: '',
      estMonthly: '',
      message: '',
      service: 'Digital Marketing',
      formSubmitted: false
    }

    

    this.contact = this.contact.bind(this)
  }

  componentDidMount() {
    if (Date.now() >= localStorage.getItem('expiresAt')) {
      localStorage.removeItem('expiresAt')
      this.setState({ formSubmitted: false })
    } else this.setState({ formSubmitted: true })

    emailjs.init(process.env.REACT_APP_PUBLIC_KEY)
  }

  async contact(event) {
    event.preventDefault()

    try {
        this.setState({ formLoading: true })
        
        await emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, {
            name: this.state.name,
            phoneNumber: this.state.phoneNumber,
            email: this.state.email,
            estMonthly: this.state.estMonthly,
            service: this.state.service,
            message: this.state.message
        })

        localStorage.setItem('expiresAt', Date.now() + (60 * 60 * 24 * 1000))
        this.setState({ formSubmitted: true })

    } catch (error) {
        console.log(error)
        alert('Something went wrong! Please try again later.')
    } finally {
        this.setState({ formLoading: false })
    }

    console.log(this.state)
  }

  render() {
    return (
      <div>
        {(this.state.formLoading) ? (
            <div className="spinner"><SpinnerRoundFilled color='#0f3cc9'></SpinnerRoundFilled></div>
        ) : (
            (this.state.formSubmitted) ? (
                <div className="message">Thank you for contacting us! Our team will get in touch with you within 24 hours!</div>
            ) : (
                <div>
                    <form onSubmit={(event) => this.contact(event)}>
                        <div className="row">
                            <div className="col-md-6">
                                <input type="text" value={this.state.name} onChange={(event) => this.setState({ name: event.target.value })} className='form-control mb-3' placeholder='Your name*' required />
                            </div>
                            <div className="col-md-6">
                                <input type="text" value={this.state.phoneNumber} onChange={(event) => this.setState({ phoneNumber: event.target.value })} className='form-control mb-3' placeholder='Phone number*' required />
                            </div>
                            <div className="col-md-6">
                                <input type="email" value={this.state.email} onChange={(event) => this.setState({ email: event.target.value })} className='form-control mb-3' placeholder='Email address*' required />
                            </div>
                            <div className="col-md-6">
                                <input type="text" value={this.state.estMonthly} onChange={(event) => this.setState({ estMonthly: event.target.value })} className='form-control mb-3' placeholder='Est Monthly marketing budget*' required />
                            </div>
                        </div>
                        <select className="form-select mb-3" aria-label="Select services" value={this.state.service} onChange={(event) => this.setState({ service: event.target.value })}>
                            <option value="Digital Marketing">Digital Marketing</option>
                            <option value="Visual Solutions">Visual Solutions</option>
                            <option value="Website Development">Website Development</option>
                        </select>
                        <textarea className="form-control mb-3" value={this.state.message} onChange={(event) => this.setState({ message: event.target.value })}  placeholder="Your message*" style={{height: '100px'}}></textarea>
                    
                        <div className="pb-5">
                            <div className="d-grid">
                                <button className='button text-center' type='submit'>Request a Quote</button>
                            </div>
                        </div>
                    </form>
                </div>
            )
        )}
      </div>
    )
  }
}

export default Form