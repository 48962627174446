import './Services.css';

function DigitalDesign(props) {
    return (
        <div className="services">
            <div className="ds pos">
                <div className="container">
                    <h1>Digital Collateral Design</h1>
                </div>
            </div>

            <div className="container mt-5 content">
                <p>From appealing social media visuals to eye-catching email templates, we specialize in creating aesthetically engaging designs that engage with your target audience.</p>
                <p>We develop digital marketing materials that effectively communicate your brand's message and inspire meaningful connections which includes</p>
                <ul>
                  <li>Social Media Graphics</li>
                  <li>Digital Banners</li>
                  <li>Pitch Decks</li>
                  <li>Custom Presentations</li>
                  <li>Website Graphics</li>
                  <li>Annual Reports</li>
                </ul>
                <p>Whether for online campaigns, website enrichment, or digital advertisements, our designs are precisely crafted to improve your corporate identity and make a lasting impression.</p>
            </div>
        </div>
    )
};

export default DigitalDesign;