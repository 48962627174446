import './Services.css';

function DigitalAudit(props) {
    return (
        <div className="services">
            <div className="ds pos">
                <div className="container">
                    <h1>Digital Audit</h1>
                </div>
            </div>

            <div className="container mt-5 content">
                <p>If you're an individual or organization that fully embraces the potential of digital technology and finds yourself questioning the performance of your current digital marketing efforts, rest assured that we're here to offer valuable assistance. Our expertise lies in providing tailored recommendations to elevate your business. Whether it's introducing a novel digital strategy or refining your existing one, our goal is to empower you with insights that lead to tangible improvements with</p>
                <ul>
                    <li>Search Engine Optimization Audit.</li>
                    <li>Backlinks Audit.</li>
                    <li>Market and Top-2 Competitor Analysis.</li>
                    <li>UI/UX Report.</li>
                    <li>Website Ranking.</li>
                    <li>Website Technical Audit.</li>
                    <li>Social Presence.</li>
                </ul>
            </div>
        </div>
    )
};

export default DigitalAudit;