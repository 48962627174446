import './Services.css';

function SocialMediaMarketing(props) {
    return (
        <div className="services">
            <div className="dd pos">
                <div className="container">
                    <h1>Social Media Marketing</h1>
                </div>
            </div>

            <div className="container mt-5 content">
                <p>Capture the attention of your ideal audience by strategically reaching out to them with precisely curated content, precisely when they're most receptive. Our proficiency extends to constructing and fortifying an influential social media presence, allowing us to adeptly interact with your target demographic across a diverse array of social media platforms like:</p>
                <ul>
                    <li>Facebook.</li>
                    <li>Instagram.</li>
                    <li>Twitter.</li>
                    <li>Linkedin.</li>
                    <li>Youtube.</li>
                    <li>Pinterest.</li>
                </ul>
                <p>This holistic approach ensures that your brand's voice resonates effectively, fostering meaningful connections and propelling your business towards an upward trajectory.</p>
            </div>
        </div>
    )
};

export default SocialMediaMarketing;