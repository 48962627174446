import './Services.css';

function VideoProduce(props) {
    return (
        <div className="services">
            <div className="ds pos">
                <div className="container">
                    <h1>Video Production</h1>
                </div>
            </div>

            <div className="container mt-5 content">
                <p>Bring your vision to life with our top-tier Video Production services.</p>
                <p>Whether you want to create marketing videos, engaging educational content, or riveting visual narrative, our team of qualified professionals is here to help you. We recognize the power of visual storytelling and personalize our approach to successfully express your message while captivating your audience. Allow us to turn your thoughts into spectacular graphics that will leave an impression.</p>
            </div>
        </div>
    )
};

export default VideoProduce;