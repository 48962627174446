import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../assets/logo.png';
import { faFacebook, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons'
import './Footer.css';

function Footer() {
    return (
        <div className="footer">
            <div className="container">
            <div className="row pt-5 border-top">
                    <div className="col-xl-3 col-6">
                        <h5>Our Concerns</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item my-2">
                                <a href="https://senoark.com" className="nav-link p-0 text-muted">Senoark</a>
                            </li>
                            <li className="nav-item my-2">
                                <a href="https://senoarkmart.senoark.com/" className="nav-link p-0 text-muted"> Marketplace</a>
                            </li>
                            <li className="nav-item my-2">
                                <a href="https://senoarknews.com" className="nav-link p-0 text-muted">News</a>
                            </li>
                            <li className="nav-item my-2">
                                <a href="/" className="nav-link p-0 text-muted">Technologies</a>
                            </li>
                        </ul>
                    </div>
                    
                    <div className="col-xl-3 col-6">
                        <h5>Support</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item my-2">
                                <a href="/" className="nav-link p-0 text-muted">FAQ</a>
                            </li>
                            <li className="nav-item my-2">
                                <a href="/contact-us" className="nav-link p-0 text-muted">Contact us</a>
                            </li>
                        </ul>
                    </div>



                    <div className="col-xl-3 col-6">
                        <h5>Useful links</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item my-2">
                                <a href="https://asklegalx.com" className="nav-link p-0 text-muted">LegalX</a>
                            </li>
                            <li className="nav-item my-2">
                                <a href="https://legalxpartners.com" className="nav-link p-0 text-muted">LegalX Partners</a>
                            </li>
                            <li className="nav-item my-2">
                                <a href="https://yourlawyer.com" className="nav-link p-0 text-muted">Your Lawyer</a>
                            </li>
                        </ul>
                    </div>

                    <div className="col-xl-3 col-12 my-3">
                        <h2>Connect with us</h2>
                        <div className="d-flex">
                            <a href="https://www.facebook.com/senoarklimited"><FontAwesomeIcon className="icon" icon={faFacebook} size='1x'/></a>
                            <a href="https://www.instagram.com/lifeatsenoark/"><FontAwesomeIcon className="icon" icon={faInstagram} size='1x'/></a>
                            <a href="https://www.linkedin.com/company/senoark-digital/?originalSubdomain=bd"><FontAwesomeIcon className="icon" icon={faLinkedin} size='1x'/></a>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-row justify-content-start align-items-center py-3 border-top">
                    <img src={logo} alt="logo" className='logo' width="150px"/>
                    <p className="col-md-5 mb-0 text-muted copyright">
                        Copyright © 2024 Senoark Digital. All rights reserved.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Footer;