import './Services.css';

function PerformanceMarketing(props) {
    return (
        <div className="services">
            <div className="ds pos">
                <div className="container">
                    <h1>Performance Marketing</h1>
                </div>
            </div>

            <div className="container mt-5 content">
                <p>Are you in pursuit of business expansion and seeking to unlock revenue streams with a remarkable return on investment? Look no further, as we stand ready to champion your cause! Embracing a role as dedicated brand catalysts, Senoark Digital commits to ushering businesses into a realm of exceptional growth and tangible results.</p>
                <p>Our accomplished team of Performance Marketers operates as architects of transformation, harnessing the potency of paid and social media advertisements to orchestrate a surge of precisely targeted traffic to your enterprise. The outcome? An augmented brand presence that commands attention and catalyzes conversions. Rooted in a data-driven ethos, our approach is centered around an incisive analysis of your unique business objectives. From there, we craft bespoke marketing strategies that not only resonate with your vision but also deliver unparalleled growth and resounding success. Join hands with us and embark on a journey towards sustained prosperity. It includes:</p>
                <ul>
                    <li>Display Advertising</li>
                    <li>Search Advertising</li>
                    <li>Social Media Advertising</li>
                    <li>Email Advertising</li>
                    <li>Affiliate Advertising</li>
                    <li>Mobile Advertising</li>
                </ul>
            </div>
        </div>
    )
};

export default PerformanceMarketing;