import './Services.css';

function ProductPackage(props) {
    return (
        <div className="services">
            <div className="ds pos">
                <div className="container">
                    <h1>Product Packaging</h1>
                </div>
            </div>

            <div className="container mt-5 content">
                <p>Discover the perfect packaging solution for your products with our exceptional Product Packaging services.</p>
                <p>We recognize the significance of making a good first impression, which is why our designs are customized to represent your business identity and resonate with your target demographic. From concept to implementation, we ensure that every aspect is precisely developed to elevate and distinguish your products on the shelves. Elevate your brand's packaging with us and leave a lasting impression on your consumers.</p>
            </div>
        </div>
    )
};

export default ProductPackage;