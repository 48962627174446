import { Component } from 'react';
import Carousel from "react-multi-carousel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import TypeWriter from './typewriter.js';
import { ParticlesBackground } from '../../components/Components.jsx';
import Form from '../../components/contact/form.jsx';

import "react-multi-carousel/lib/styles.css";
import './Home.css'

import appointment from '../../assets/appointment.png'

import { faFacebook, faLinkedin, faInstagram, faAmazon } from '@fortawesome/free-brands-svg-icons'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'

export default class Home extends Component {
    constructor() {
        super()

        this.state = {
            responsive: {
                superLargeDesktop: {
                    breakpoint: { max: 4000, min: 3000 },
                    items: 5
                  },
                  desktop: {
                    breakpoint: { max: 3000, min: 1024 },
                    items: 3
                  },
                  tablet: {
                    breakpoint: { max: 1024, min: 464 },
                    items: 2
                  },
                  mobile: {
                    breakpoint: { max: 464, min: 0 },
                    items: 1
                  }
            }
        }
    }

    componentDidMount() {
        function init() {
            const txtElement = document.querySelector('.txt-type');
            const words = JSON.parse(txtElement.getAttribute('data-words'));
            const wait = txtElement.getAttribute('data-wait');
    
            new TypeWriter(txtElement, words, wait);

            if (window.location.href === 'https://senoarkdigital.com/#appointment') window.location.href = 'https://senoarkdigital.com/#appointment'
        }
        
        init();
    }

    render() {
        return (
            <div className="home">
                <div className="particles-main">
                    <ParticlesBackground />
                </div>

                <div className="why">
                    <div className="why-text">
                        <h1 >We've been <span className="txt-type" data-wait="3000" data-words='["designing", "creating", "workshopping", "illuminating", "transforming"]'></span> digital marketing for Bangladeshi brands since 2019</h1>
                    </div>
                </div>

                <div className="soow py-5">
                        <div className="soow-text">
                            <h1>Some of our Works.</h1>
                            <p> We’ve always strived to put up a good work. It is not just about having a checklist – meeting deadlines or keeping budgets; it is about understanding the client’s needs and maximizing the use of available resources to craft an output that is fit for the target audience.⁣ Showcasing some of that work that we delivered.</p>
                            <button className="button mt-2" onClick={() => window.location.href = '/our-work'}>See More</button>
                        </div>
                </div>

                <div className="people py-5">
                    <div className="container-fluid">
                        <div className="row d-flex align-items-center">
                            <div className="col-md-3">
                                <h1 className='people-heading'>Meet Our People.</h1>
                            </div>
                            <div className="col-md-9">
                                <div className="d-flex flex-row-reverse">
                                    <div className="peopleimg two">
                                        <h2 className="name">Syed Joheb Hassan</h2>
                                    </div>
                                    <div className="peopleimg three">
                                        <h2 className="name">Muiz Shahab Uddin</h2>
                                    </div>
                                    <div className="peopleimg one">
                                        <h2 className="name">Maliha Rahham</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="pricing">
                    <div className="container">
                        <h1 className='heading'>Our Packages</h1>
                        <div className="row d-flex align-items-center">
                            <div className="col-md-4">
                                <div className="pricing-card">
                                    <h5>Basic</h5>

                                    <div className="points">
                                        <div><FontAwesomeIcon icon={faCircleCheck} className="icon" style={{color: 'var(--primary-blue)'}}></FontAwesomeIcon> 8 Static Posts</div>
                                        <div><FontAwesomeIcon icon={faCircleCheck} className="icon" style={{color: 'var(--primary-blue)'}}></FontAwesomeIcon> 1 Dynamic Posts</div>
                                        <div><FontAwesomeIcon icon={faCircleCheck} className="icon" style={{color: 'var(--primary-blue)'}}></FontAwesomeIcon> Social Media Management</div>
                                        <div><FontAwesomeIcon icon={faCircleCheck} className="icon" style={{color: 'var(--primary-blue)'}}></FontAwesomeIcon> 2x Monthly Meetings</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                            <div className="pricing-card big">
                                    <h5>Standard</h5>

                                    <div className="points">
                                        <div><FontAwesomeIcon icon={faCircleCheck} className="icon" style={{color: 'var(--primary-blue)'}}></FontAwesomeIcon> 16 Static Posts</div>
                                        <div><FontAwesomeIcon icon={faCircleCheck} className="icon" style={{color: 'var(--primary-blue)'}}></FontAwesomeIcon> 2 Dynamic Posts</div>
                                        <div><FontAwesomeIcon icon={faCircleCheck} className="icon" style={{color: 'var(--primary-blue)'}}></FontAwesomeIcon> Social Media Management</div>
                                        <div><FontAwesomeIcon icon={faCircleCheck} className="icon" style={{color: 'var(--primary-blue)'}}></FontAwesomeIcon> 2x Monthly Meetings</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                            <div className="pricing-card">
                                    <h5>Premium</h5>

                                    <div className="points">
                                        <div><FontAwesomeIcon icon={faCircleCheck} className="icon" style={{color: 'var(--primary-blue)'}}></FontAwesomeIcon> 20 Static Posts</div>
                                        <div><FontAwesomeIcon icon={faCircleCheck} className="icon" style={{color: 'var(--primary-blue)'}}></FontAwesomeIcon> 3 Dynamic Posts</div>
                                        <div><FontAwesomeIcon icon={faCircleCheck} className="icon" style={{color: 'var(--primary-blue)'}}></FontAwesomeIcon> Social Media Management</div>
                                        <div><FontAwesomeIcon icon={faCircleCheck} className="icon" style={{color: 'var(--primary-blue)'}}></FontAwesomeIcon> 2x Monthly Meetings</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="appointment my-5 py-5" id="appointment">
                    <div className="container">
                        <div className="row d-flex align-items-center">
                            <div className="col-md-6">
                                <div className="form">
                                    {(localStorage.getItem('expiresAt')) ? null : (
                                        <div className="head">
                                            <h1>Get a Quote</h1>
                                            <h3 className='text-uppercase'>start marketing your business today.</h3>
                                        </div>
                                    )}
                                    <Form></Form>
                                </div>
                            </div>
                            <div className="col-md-6 d-flex justify-content-end align-items-center">
                                <img src={appointment} className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="credentials">
                    <div className="cred-comp row align-items-center">
                            <div className="col-md-4 text-center">Our Credentials</div>
                            <div className="col-md-8 text-center">
                                <Carousel responsive={this.state.responsive} swipeable={false}  draggable={false} arrows={false} infinite={true} autoPlay={true} autoPlaySpeed={3000} itemClass="carousel-item-padding-40-px">
                                    <FontAwesomeIcon className="icon" icon={faFacebook} size='3x'/>
                                    <FontAwesomeIcon className="icon" icon={faInstagram} size='3x'/>
                                    <FontAwesomeIcon className="icon" icon={faLinkedin} size='3x'/>
                                    <FontAwesomeIcon className="icon" icon={faAmazon} size='3x'/>
                                </Carousel>
                            </div>
                    </div>
                </div>
            </div>
        )
    }
}