import './Services.css';

function QueryCommunityManagement(props) {
    return (
        <div className="services">
            <div className="ux pos">
                <div className="container">
                    <h1>Query and Community Management</h1>
                </div>
            </div>

            <div className="container mt-5 content">
                <p>Navigate the world of online engagement with confidence through our Query and Community Management services.  Our experienced team is committed to managing enquiries, comments, and conversations across several platforms, providing prompt and helpful responses. We address problems while also cultivating relationships that increase brand loyalty by actively interacting with your community.</p>
                <p>We're here to support welcoming interactions, handle difficulties, and develop a strong online community around your company, from social media to forums. You can count on us to maintain your digital setting dynamic, informative, and user-focused.</p>
            </div>
        </div>
    )
};

export default QueryCommunityManagement;